import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import {
  AttachedFile,
  Collections,
  Firebase,
  MerchantDocument,
  merchantDocumentsHiddenFromUi,
  StoragePath,
  useAttachments,
  useNotification,
  UserRoles,
} from '@ozark/common';
import {ConfirmationDialog, FileToUpload, InsertAttachmentsDialog} from '@ozark/common/components';
import {AttachedFiles, handleAttachmentUpload} from '@ozark/common/util';
import {useEffect, useState} from 'react';
import {FieldErrors, FieldValues} from 'react-hook-form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {useStore} from '../../store';

interface Props {
  isPortalUser: boolean;
  errors: FieldErrors;
  setValue?: UseFormSetValue<FieldValues>;
}

type DeleteDialogOpen = {
  isOpen: boolean;
  confirmationAction?: (() => Promise<void>) | null;
  message?: string;
};

export const UploadDocuments = ({isPortalUser, errors, setValue}: Props) => {
  const {authUser, application} = useStore();
  const showNotification = useNotification();
  const existingAttachments = useAttachments(
    application.data?.id,
    Collections.applications,
    isPortalUser
  );
  const [attachments, setAttachments] = useState<AttachedFiles>({});
  const [, setPendingMerchantDocument] = useState<MerchantDocument | null>(null);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<DeleteDialogOpen>({
    isOpen: false,
  });
  const [documentTypeOptions, setDocumentTypeOptions] = useState<MerchantDocument[]>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [skipAttachmentsChangesInUseEffect, setSkipAttachmentsChangesInUseEffect] = useState(false);

  useEffect(() => {
    setDocumentTypeOptions(
      Object.values(MerchantDocument)
        .filter(x => !merchantDocumentsHiddenFromUi.includes(x))
        .sort()
    );
  }, []);

  const isCrmUser =
    authUser.claims &&
    authUser?.claims?.role !== UserRoles.agent &&
    authUser?.claims?.role !== UserRoles.merchant;
  const isAgent = authUser.claims && authUser?.claims?.role !== UserRoles.agent;

  const handleAttachmentClick = (url?: string) => () => {
    if (!url) return;
    fetch(url).then(async response => {
      const blob = await response.blob();
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('target', '_blank');
      a.click();
    });
  };

  const handleAttachClick = () => {
    setCreateDialogOpen(true);
  };

  const deleteAttachment = async (attachmentKey: string, attachment: AttachedFile) => {
    const snapshot = await Firebase.firestore
      .collection(Collections.applications)
      .doc(application.data.id)
      .collection(Collections.attachments)
      .doc(attachment.attachmentId)
      .get();
    if (!snapshot.exists) {
      return;
    }

    await snapshot.ref.set({deleted: true}, {merge: true});

    delete attachments[attachmentKey];
    setAttachments(attachments);
    if (Object.keys(attachments).length === 0 && typeof setValue === 'function') {
      setValue('mpaAttached', false, {shouldDirty: true, shouldTouch: true, shouldValidate: false});
    }
  };

  const showDeleteConfirmation = (attachmentKey: string, attachment: AttachedFile) => {
    if (!attachment.attachmentId) {
      return;
    }
    let message: string = 'Are you sure you want to delete this document?';
    setDeleteConfirmationDialog({
      isOpen: true,
      confirmationAction: async () => {
        await deleteAttachment(attachmentKey, attachment);
      },
      message,
    });
  };

  useEffect(() => {
    // we need to get list of existing attachments only on the step/page load
    if (
      !existingAttachments.attachments.data ||
      existingAttachments.attachments.promised ||
      existingAttachments.attachments.data.length === 0 ||
      skipAttachmentsChangesInUseEffect
    ) {
      return;
    }
    if (typeof setValue === 'function') {
      setValue('mpaAttached', true, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
    }
    const attachedFiles: AttachedFiles = {};
    for (let existingAttachment of existingAttachments.attachments.data) {
      attachedFiles[existingAttachment.id] = {
        name: existingAttachment.name,
        label: existingAttachment.name,
        url: existingAttachment.downloadUrl,
        originalName: existingAttachment.label,
        attachmentId: existingAttachment.id,
        uid: existingAttachment.uid,
      } as AttachedFile;
    }
    setAttachments(attachedFiles);
    // eslint-disable-next-line
  }, [
    existingAttachments.attachments.promised,
    skipAttachmentsChangesInUseEffect,
    existingAttachments.attachments?.data?.length,
  ]);

  const uploadAttachment = (
    filesToUpload: FileToUpload[],
    index: number,
    resolve: (value: unknown) => void
  ) => {
    if (index > filesToUpload.length - 1) {
      if (typeof setValue === 'function') {
        setValue('mpaAttached', true, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
      }
      resolve(true);
      setCreateDialogOpen(false);
      setSkipAttachmentsChangesInUseEffect(false);
      return;
    }

    const fileToUpload = filesToUpload[index];

    handleAttachmentUpload({
      author: {
        uid: authUser.data?.uid ?? '',
        name: authUser.data?.displayName ?? '',
        user: isCrmUser ? 'erp' : isAgent ? 'agent' : 'merchant',
      },
      file: fileToUpload.file,
      cloudPath: `${StoragePath.applications}/${application.data?.id}`,
      entityId: application.data?.id,
      collection: Collections.applications,
      pendingDocument: fileToUpload.uploadDocument ?? fileToUpload.file.name,
      setPendingDocument: setPendingMerchantDocument,
      files: attachments,
      setFiles: setAttachments,
      showNotification: showNotification,
      onComplete: () => {
        uploadAttachment(filesToUpload, index + 1, resolve);
      },
      skipSuccessNotification: true,
      folderName: fileToUpload.folderName,
    });
  };

  const handleSelectedFiles = async (filesToUpload: FileToUpload[]) => {
    try {
      setSkipAttachmentsChangesInUseEffect(true);
      await new Promise(resolve => {
        uploadAttachment(filesToUpload, 0, resolve);
      });
      showNotification('success', 'File(s) successfully uploaded.');
    } catch (err: any) {
      console.error(`failed to upload file. ${err.toString()}`);
      showNotification('error', 'Failed to upload.');
    }
  };

  return (
    <>
      {createDialogOpen && (
        <InsertAttachmentsDialog
          initialFolderName={null}
          documentTypeOptions={documentTypeOptions}
          folderNamesOptions={[]}
          onSubmit={handleSelectedFiles}
          onClose={() => setCreateDialogOpen(false)}
          hideFolder
        />
      )}
      {Object.keys(attachments).length > 0 && (
        <Grid item xs={12}>
          <List>
            {Object.keys(attachments).map(name => {
              const progress = attachments[name].progress;
              return (
                <ListItem
                  key={name}
                  button
                  onClick={handleAttachmentClick(attachments[name].url)}
                  disabled={!attachments[name].url}
                >
                  <ListItemIcon>
                    {!attachments[name].url ? (
                      <CircularProgress size={24} variant="determinate" value={progress} />
                    ) : (
                      <DoneIcon color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={attachments[name].label}
                    secondary={attachments[name].originalName}
                  />
                  {(isCrmUser || attachments[name].uid === authUser.data?.uid) && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => showDeleteConfirmation(name, attachments[name])}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          id="imageUploadButton"
          name="image"
          onClick={handleAttachClick}
          variant="outlined"
          color="secondary"
          size="large"
        >
          Attach New Document
        </Button>
        {errors && errors.mpaAttached && typeof errors.mpaAttached.message === 'string' && (
          <Grid item xs={12} sx={{marginTop: '10px'}}>
            <Alert severity="error">{errors.mpaAttached.message}</Alert>
          </Grid>
        )}
      </Grid>
      {deleteConfirmationDialog.isOpen && (
        <ConfirmationDialog
          title="Confirmation"
          message={deleteConfirmationDialog.message ?? ''}
          onClose={() =>
            setDeleteConfirmationDialog({
              isOpen: false,
            })
          }
          onConfirm={deleteConfirmationDialog.confirmationAction ?? null}
        />
      )}
    </>
  );
};
