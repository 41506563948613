import {FieldValue} from '@google-cloud/firestore';
import {
  AccountStatus,
  IndustryVertical,
  PlacementType,
  ProcessingTypes,
  RiskLevel,
  ShippingService,
  ShippingSpeed,
  ShippingType,
  TerminatedSubCategories,
  TransferStatus,
  UserRoles,
} from '../constants';
import {Dispositions} from '../constants/Dispositions';
import {LocationTypes} from '../constants/LocationTypes';
import {OwnershipTypes} from '../constants/OwnershipTypes';
import {OwnerTitles} from '../constants/OwnerTitles';
import {Platforms} from '../constants/Platforms';
import {IpStackResponse} from '../functions/callable/ipStack';
import {Overwrite} from '../helpers/type';
import {formatPhone} from '../lib/formatPhone';
import {AttachmentableEntity} from './AttachmentableEntity';
import {AuditableDocument} from './Audit';
import {UniversalSnapshot, UniversalTimestamp, UniversalTimestampRaw} from './compat';
import {
  EquipmentAdditionalField,
  EquipmentConnectionType,
  EquipmentFileType,
  EquipmentTipMode,
} from './Equipment';
import {RateSet} from './RateProgram';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

//every agent support disposition should start with AS for a value
export const AsDispositions = Object.values(Dispositions).filter(e => e.startsWith('AS'));

//every UW disposition should start with UW for a value
export const UwDispositions = Object.values(Dispositions).filter(e => e.startsWith('UW'));

export type EquipmentAttachment = {
  attachmentId: string;
  cloudPath: string;
  name: string;
};

export type DispositionLog = {
  updatedAt: UniversalTimestamp;
  disposition: Dispositions;
  minutes: number;
  uid?: string;
};

export type ApplicationEquipment = {
  id: string;
  name: string;
  price: number;
  isGateway?: boolean;
  platform: Platforms;
  imageUrl: string;
  duration: string;
} & Partial<{
  accessories: string[];
  connectionType: EquipmentConnectionType;
  fileType: EquipmentFileType;
  tipMode: EquipmentTipMode;
  cashDiscount: boolean;
  cashDiscountPercent: number;
  autoBatch: boolean;
  autoBatchTime: string;
  contactless: boolean;
  shippingSpeed: ShippingSpeed;
  virtualSetupFee: number;
  virtualMonthlyFee: number;
  virtualTransactionFee: number;
  placementType?: PlacementType;
  paymentType: string;
  serialNumber?: string;
  description: string;
  VARSheetAttachmentUrl?: string;
  tid?: string;
  sendTrackingNumber?: string;
  receiveTrackingNumber?: string;
  isVarSheet?: boolean;
  softwareName?: string;
  additionalFields?: EquipmentAdditionalField[];
  pinDebitInjection?: string;
  attachment?: EquipmentAttachment;
}>;

export type ApplicationShipping = {
  attention: string;
  other: boolean;
  select: ShippingType;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
};

export type ApplicationProgram = {
  id: string;
  name: string;
  processingTypes: {
    cardPresent: RateSet;
    cardNotPresent: RateSet;
    eCommerce: RateSet;
  };
};

export type GeoLocation = {
  lat?: number;
  lng?: number;
};

export type GeoTimeZone = {
  /**
   * the offset for daylight-savings time in seconds.
   * This will be zero if the time zone is not in Daylight Savings Time during the specified `timestamp`.
   */
  dstOffset: number;
  /** the offset from UTC (in seconds) for the given location. This does not take into effect daylight savings. */
  rawOffset: number;
  /**
   * a string containing the ID of the time zone, such as "America/Los_Angeles" or "Australia/Sydney".
   * These IDs are defined by [Unicode Common Locale Data Repository (CLDR) project](http://cldr.unicode.org/),
   * and currently available in file [timezone.xml](http://unicode.org/repos/cldr/trunk/common/bcp47/timezone.xml).
   * When a timezone has several IDs, the canonical one is returned. In timezone.xml, this is the first alias of each timezone.
   * For example, "Asia/Calcutta" is returned, not "Asia/Kolkata".
   */
  timeZoneId: string;
  /**
   * a string containing the long form name of the time zone.
   * This field will be localized if the `language` parameter is set.
   * eg. "Pacific Daylight Time" or "Australian Eastern Daylight Time"
   */
  timeZoneName: string;
};

export type PlaidSecret = {
  accessToken: string;
};

export type PlaidAccount = {
  name: string;
  bankName: string;
  accountId: string;
  accountNumber: string;
  routingNumber: string;
  createdAt: UniversalTimestamp;
};

export type FinicityAccount = {
  bankName: string;
  accountId: string;
  customerId: string;
};

export type ApplicationResidualShare = {
  recipientAgentId: string;
  recipientGroupId: string;
  splitPercent: number;
  hideVisibility: boolean;
  updatedAt: UniversalTimestamp;
  createdBy: string;
};

export type ApplicationResidualShareLocks = {
  groupVersion?: number;
  agentVersion?: number;
  masterAgentVersion?: number;
};

export type ApplicationAssignedAgentResidualShare = {
  splitPercent: number;
  updatedAt: UniversalTimestamp;
  createdBy: string;
};

export interface IMerchantAcqInfo {
  bankNumber?: string;
  group?: string;
  associationNumber?: string;
  accountNumber?: string;
  associationName?: string;
  groupLevelName?: string;
  sic?: string;
  class?: string;
  dbaName?: string;
  dbaCity?: string;
  dbaState?: string;
  dbaZip?: string;
  phone1?: string;
  phone2?: string;
  businessLicense?: string;
  bankOfficer1?: string;
  bankOfficer2?: string;
  userBankNumber?: string;
  userBranchNumber?: string;
  federalTaxId?: string;
  stateTaxId?: string;
  edcFlag?: boolean;
  repCode?: string;
  merchantStatus?: string;
  userFlag1?: string;
  userFlag2?: string;
  userData1?: string;
  userData2?: string;
  userData3?: string;
  userData4?: string;
  userData5?: string;
  userAccount1?: string;
  userAccount2?: string;
  achFlag?: boolean;
  visaCPS2?: boolean;
  visaCPS1?: boolean;
  visaSupermarket?: boolean;
  visaPSRF?: boolean;
  visaEIRF?: boolean;
  mcMerit3?: boolean;
  mcMerit1?: boolean;
  mcSupermarket?: boolean;
  mcPtCat?: boolean;
  mcWarehouse?: boolean;
  mcPRM?: boolean;
  discoverEligibility?: boolean;
  merchantType?: string;
  incStatus?: string;
  memberId?: string;
  grsFlag?: boolean;
  ownerName?: string;
  managerName?: string;
  asstManagerName?: string;
  otherName?: string;
  ownerLicenseNumber?: string;
  lastStatementDate?: UniversalTimestamp;
  openDate?: UniversalTimestamp;
  lastCreditCheck?: UniversalTimestamp;
  lastCallDate?: UniversalTimestamp;
  nextCallDate?: UniversalTimestamp;
  finStatementDueDate?: UniversalTimestamp;
  finStatementReqDate?: UniversalTimestamp;
  storageFlag?: boolean;
  statementCount?: string;
  addressDiscountIndicator?: string;
  addressRclList?: string;
  addressCrbList?: string;
  addressCardMailer?: string;
  addressIrs?: string;
  addressImprintersRentals?: string;
  addressMemberFees?: string;
  addressPosTerminals?: string;
  addressMis?: string;
  addressAdjustments?: string;
  addressChargebacks?: string;
  addressUniqueMessage?: string;
  addressBet1?: string;
  addressBet2?: string;
  addressBet3?: string;
  interchangeDollar?: string;
  interchangeCount?: string;
  destOverall?: string;
  destDeposits?: string;
  destAdjustments?: string;
  destChargebacks?: string;
  destReversals?: string;
  destChargebackReversals?: string;
  destDdaAdjustments?: string;
  destBatchAdjustments?: string;
  destTranOption1?: string;
  destTranOption2?: string;
  amexDescriptionCode?: string;
  discoverReferenceNumber?: string;
  amexId?: string;
  discoverAcctId?: string;
  lastActivityDate?: UniversalTimestamp;
  dailyFeeIndicator?: boolean;
  mcRegId?: string;
  customerServiceNumber?: string;
  updateDateTime?: UniversalTimestamp;
  statusChangeDate?: UniversalTimestamp;
  discoverMapFlag?: boolean;
  amexOptBlueFlag?: boolean;
  amexSubmitterId?: string;
  visaDescriptor?: string;
  mcDescriptor?: string;
  url?: string;
  closeDate?: UniversalTimestamp;
  dateOfLastAuth?: UniversalTimestamp;
  dunsNumber?: string;
  multicurrencyFlag?: boolean;
  postDateDebits?: string;
  postDateCredits?: string;
  printStatementIndicator?: boolean;
  visaBin?: string;
  mcBin?: string;
  mcIca?: string;
  amexCapId?: string;
  discoverAiid?: string;
  ddaNumber?: string;
  transitRoutingNumber?: string;
  reclearFlag?: string;
  exposureAmount?: string;
  merchantActivationDate?: UniversalTimestamp;
  dateOfFirstDeposit?: UniversalTimestamp;
  dateOfLastDeposit?: UniversalTimestamp;
  transDestination?: string;
  dbaCountryCode?: string;
  merchantEmailAddress?: string;
  chargebackEmailAddress?: string;
  expansionArea?: string;

  socialSecurityNumber?: string; // we use this key for parsing config

  // we use two next fields to store values
  encryptedSocialSecurityNumber?: string;
  encryptedSocialSecurityNumberSalt?: string;
}

export type MerchantAcqInfo = IMerchantAcqInfo;

export interface IMerchantPricingInfo {
  recordType?: string;
  recordId?: string;
  bankId?: string;
  groupId?: string;
  associationId?: string;
  agentId?: string;
  merchantId?: string;
  feeCategory?: string;
  feeItemName?: string;
  feeItemHierarchyIndicator?: string;
  reCalculateAtMonthEnd?: string;
  ddaAccountType?: string;
  feeDDAOverride?: string;
  count?: string;
  includeInMonthlyMinimum?: string;
  salesTaxIndicator?: string;
  salesTaxRate?: string;
  dailyFeeIndicator?: string;
  billingMethod?: string;
  nameAndAddressUsageCode?: string;
  billingFrequency?: string;
  startDate?: UniversalTimestamp;
  stopDate?: UniversalTimestamp;
  countNGBIndicator?: string;
  amountNGBIndicator?: string;
  glItemCode?: string;
  userDataCode?: string;
  displayOnStatementCount?: string;
  displayOnStatementAmount?: string;
  displayRatesOnStatements?: string;
  displayDetailOnStatement?: string;
  combineFeeOnRateChange?: string;
  combineCode?: string;
  statementFeeDescription?: string;
  percent?: string;
  perItem?: string;
  qualificationPercent?: string;
  qualificationPerItem?: string;
  filler1?: string;
  filler2?: string;
  feeItemPricingMethod?: string;
  lastMaintenance?: UniversalTimestamp;
  lastMaintainedBy?: string;
  incomeRateGroupName?: string;
}

export type MerchantPricingInfo = IMerchantPricingInfo;

export interface IMerchantTierDetailInfo {
  recordType?: string;
  recordId?: string;
  bankId?: string;
  merchantId?: string;
  feeCategory?: string;
  feeItemName?: string;
  tierMINFactor?: string;
  tierMaxFactor?: string;
  tierFeePercent?: string;
  tierFeePerItem?: string;
  tierStatementFeeDescription?: string;
  incomeRateGroupName?: string;
  tierFixedAmount?: string;
}

export type MerchantTierDetailInfo = IMerchantTierDetailInfo;

export type ReferenceApplication = {
  appId: string;
  distinguishableId: string;
  createdBy: string;
  createdAt: UniversalTimestamp;
};

export type PendingReminderSettings = {
  isEnabled: boolean;
  delay?: number; // delay in minutes
  count?: number;
  nextCallAt?: UniversalTimestamp;
};

export type Application = AuditableDocument & ApplicationData & AttachmentableEntity;

export enum BillingMethod {
  Monthly = 'Monthly',
  Annual = 'Annual',
}
export enum ProductOwner {
  Merchant = 'Merchant',
  Vendor = 'Vendor',
}

export enum ProductPurchasedBy {
  Merchant = 'Merchant',
  Vendor = 'Vendor',
}

export enum EbtAcceptanceStatus {
  Yes = 'Yes',
  No = 'No',
  CashOnly = 'EBT Cash Only',
}
export enum CustomerReceivesProductService {
  daysFrom0To7 = '0-7 days',
  daysFrom8To14 = '8-14 days',
  daysFrom15To30 = '15-30 days',
  days30Plus = '30+ days',
}
export type ApplicationData = {
  acceptPIN: boolean;
  agentNotes?: string;
  bankAccountNumber: string;
  bankName: string;
  boardedAt?: UniversalTimestamp;
  businessAddress1: string;
  businessAddress2?: string;
  businessCity: string;
  businessLocation?: GeoLocation;
  businessTimeZone?: GeoTimeZone;
  businessPhone: string;
  businessType: string;
  businessStartYear: number;
  businessState: string;
  businessZip: string;
  complete: boolean;
  completedAt: UniversalTimestamp;
  createdAt: UniversalTimestamp;
  createdBy: string;
  customerServiceEmail: string | null;
  dateOfBirth: UniversalTimestamp;
  dateOfBirthBackup: UniversalTimestamp; // technical backup field
  discoverMid: string;
  disposition: Dispositions;
  dispositionNotifyAgent?: boolean;
  dispositionNotifyMerchant?: boolean;
  pendingReminder?: PendingReminderSettings;
  dispositionUpdatedAt: UniversalTimestamp;
  dispositionMovedToAsAt: UniversalTimestamp | null;
  dispositionMovedToUwAt: UniversalTimestamp | null;
  uwUploadedAt: UniversalTimestamp | null; // latest document upload date | UW Pending disposition
  distinguishableId: string;
  doingBusinessAs: string;
  ebtAcceptance: EbtAcceptanceStatus;
  ebtFNS: string;
  customerReceivesProductService?: string;
  productOwner?: 'Merchant' | 'Vendor';
  isCustomerDepositRequired?: boolean;
  customerDepositPercentage?: number;
  productPurchasedNames?: string;
  productPurchasedBy?: 'Merchant' | 'Vendor';
  isFulfillmentCenterNamesDefined?: boolean;
  fulfillmentCenterNames?: string;
  isCallCenterNamesDefined?: boolean;
  callCenterNames?: string;
  isCbManagementSystemsNamesDefined?: boolean;
  cbManagementSystemsNames?: string;
  shippingServices?: ShippingService[];
  otherShippingServices?: string;
  email: string;
  encryptedSocialSecurityNumber: string;
  encryptedSocialSecurityNumberSalt: string;
  endIpInfo?: Partial<IpStackResponse>;
  estimatedAverageSale: number;
  estimatedHighestSale: number;
  estimatedMonthlyCreditCardSales: number;
  estimatedMonthlyCreditCardSalesAmex: number;
  federalTaxId: string;
  filingState: string;
  firstName: string;
  firstName2: string;
  firstName3: string;
  firstName4: string;
  homeAddress1: string;
  homeAddress2?: string;
  homeCity: string;
  homeState: string;
  homeZip: string;
  lastName: string;
  lastName2: string;
  lastName3: string;
  lastName4: string;
  legalBusinessName: string;
  logRocketSessionURLCollection?: string[];
  mailingAddress1: string;
  mailingAddress2?: string;
  mailingAddressDifferent: boolean;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  mcc: string;
  mid?: string;
  otherOwners: boolean;
  otherOwnersData?: ApplicationOwner[];
  ownershipType: OwnershipTypes;
  percentOwnership: number;
  platform: Platforms;
  previouslyProcessed: boolean;
  processingType: ProcessingTypes;
  percentSwiped: number;
  percentKeyed: number;
  percentInternet: number;
  percentInternational: number;
  percentB2b: number;
  percentReserve?: number | null;
  referenceApp?: ReferenceApplication;
  refundPolicy: string;
  refundPolicyExplained: string;
  registrationEmailSentAt: UniversalTimestamp;
  routingNumber: string;
  signature?: {
    downloadUrl: string;
    fullPath: string;
  };
  signedByUid?: string;
  signedInPerson?: boolean;
  startIpInfo?: Partial<IpStackResponse>;

  startApplicationLink?: string;
  isCompletedByTransferredLink?: boolean;
  socialSecurityNumberLast4: string;
  title: OwnerTitles;
  locationType?: LocationTypes;
  // When application is first created this will be user id of anonymous user or agent who created the app.
  // It will be then re-assigned to a ERP user when it goes through the process.
  uid: string | null;
  unassigned: boolean;
  riskAssigneeUid?: string | null;
  updatedAt: UniversalTimestamp;
  lastProcessedAt: UniversalTimestamp;
  nextPath?: string | FieldValue;
  nextShowValidation?: boolean;
  website?: string;
  ipAddress: string;
  equipmentId: string;
  equipment?: ApplicationEquipment;
  equipmentAdditional?: ApplicationEquipment[];
  shipping?: ApplicationShipping;
  programId: string;
  program?: ApplicationProgram;
  rateSet: RateSet;
  dispositionHistory?: DispositionLog[];
  salesResponsibilityCode: string;
  salesResponsibilityCodeExt?: string;
  riskLevel?: RiskLevel;
  industryVertical: IndustryVertical;
  externalId?: string;
  overwrites?: string[];
  isPortalApplication: boolean;
  group: {
    id: string;
    logoUrl: string;
    name: string;
  };
  agent?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  merchantUid?: string; //main merchant uid
  deleted?: boolean | null;
  tags?: {[key: string]: string};
  locks?: {[_ in keyof Omit<Application, 'locks'>]: boolean};
  boardingNotes?: string;
  boardingNotesConditions?: string[];
  isConditionallyApproved?: boolean;
  isClosed?: boolean;
  transferStatus: ApplicationTransferInfo;
  plaidAccount?: PlaidAccount;
  finicityAccount?: FinicityAccount;
  instantIdResult?: 'disabled' | 'error' | 'failed' | 'pass';
  instantIdFailureDescription?: string;
  leadId?: string;
  pdfGenerationTimestamp?: UniversalTimestamp;

  suppressUwPendingNotificationsUntil?: UniversalTimestamp; // suppress Upload Documents (for UW Pending) until

  // Risk module properties
  accountStatus?: ApplicationAccountStatus;
  inactivityEmailSentAt?: UniversalTimestamp;
  riskStatus?: string;
  riskLabels?: string[];

  associatedAgents?: string[]; // assigned agent.id + his master agent id if applicable
  associatedMerchants?: string[]; //contains UIDs of all merchants: all sub-merchants and main merchant uid
  associatedApplications?: {id: string; mid?: string}[];
  merchantAdvantageProgram?: {
    amount: number;
    billingMethod: BillingMethod;
  };
  personaId?: PersonaVerification;

  residualShares?: ApplicationResidualShare[];
  splitPercentOverride?: ApplicationAssignedAgentResidualShare;
  residualShareLocks?: ApplicationResidualShareLocks;
};

export type ApplicationPdfGenerationTask = {
  createdAt: UniversalTimestamp;
  name?: string;
  trace?: string;
  payload?: string;
};

export type PersonaVerification = {
  isVerified?: boolean;
  driverLicenseNumber?: string;
  referenceId: string;
  rerefenceIdSalt: string;
  data?: any;
};

export type ApplicationOwner = {
  firstName: string;
  lastName: string;
  ownershipType: OwnershipTypes;
  percentOwnership: number;
  dateOfBirth: UniversalTimestamp | string;
  dateOfBirthBackup: UniversalTimestamp | string; // technical backup field
  homeAddress1: string;
  homeAddress2: string;
  homeCity: string;
  homeState: string;
  homeZip: string;
  encryptedSocialSecurityNumber: string;
  encryptedSocialSecurityNumberSalt: string;
  socialSecurityNumberLast4: string;
  title: string;
  driverLicenseState: string;
  driverLicenseNumber: string;
  cellPhone?: string;
};

export type ApplicationTransferInfo = {
  transferStatus: TransferStatus;
  transferDate: UniversalTimestamp;
  viewedDate?: UniversalTimestamp;
  transferredBy?: {
    uid: string;
    role: UserRoles; // role is needed to determine if user is agent or erp user
  };
  recipientEmail?: string;

  siteJumpTransferredBy?: {
    uid: string;
    role: UserRoles; // role is needed to determine if user is agent or erp user
  };
};

export type ApplicationAccountStatus = {
  status?: AccountStatus;
  subCategory?: TerminatedSubCategories;
  eligibleReleaseDate?: UniversalTimestamp;
  statusChangeDate?: UniversalTimestamp;
};

export type ApplicationInput = Overwrite<
  Application,
  {dateOfBirth: Date; dispositionMovedToAsAt: Date | null; dispositionMovedToUwAt: Date | null}
>;

export type ApplicationDuplicateView = ViewBase<ApplicationDuplicate>;
export type ApplicationDuplicatesView = ApplicationDuplicateView[];

export type ApplicationView = ViewBase<Application> & {
  duplicates?: ApplicationDuplicatesView;
};

export type ApplicationRawData = Application &
  Overwrite<
    Application,
    {
      completedAt: UniversalTimestampRaw;
      createdAt: UniversalTimestampRaw;
      dateOfBirth: UniversalTimestampRaw;
      dispositionUpdatedAt: UniversalTimestampRaw;
      dispositionMovedToAsAt: UniversalTimestampRaw | null;
      dispositionMovedToUwAt: UniversalTimestampRaw | null;
      updatedAt: UniversalTimestampRaw;
    }
  >;

export type ApplicationNotification = {
  createdAt: UniversalTimestamp;
  disposition: Dispositions;
};

export const ApplicationNonIndexableFields: (
  | keyof ApplicationData
  | 'manuallyIndexedAt'
  | 'scoreCard'
)[] = [
  'ebtAcceptance',
  'acceptPIN',
  'associatedApplications',
  'bankAccountNumber',
  'complete',
  'encryptedSocialSecurityNumber',
  'encryptedSocialSecurityNumberSalt',
  'mailingAddressDifferent',
  'otherOwners',
  'previouslyProcessed',
  'signature',
  'startIpInfo',
  'startApplicationLink',
  'isCompletedByTransferredLink',
  'endIpInfo',
  'equipment',
  'program',
  'routingNumber',
  'dispositionHistory',
  'rateSet',
  'locks',
  'manuallyIndexedAt',
  'scoreCard',
];

export type IndexableApplication = Overwrite<
  Omit<Application, typeof ApplicationNonIndexableFields[number]>,
  {
    id: string;
    disposition: Dispositions;
    completedAt: Date | null;
    createdAt: Date | null;
    dispositionUpdatedAt: Date | null;
    updatedAt: Date | null;
    dateOfBirth: Date | null;
    riskLevel: string | null;
  }
>;

export const selectApplicationDuplicateView: ViewSelector<
  ApplicationDuplicateView,
  ApplicationDuplicate
> = snap => defaultViewSelector(snap);

export const selectApplicationView: ViewSelector<ApplicationView, Application> = snap => {
  const data = snap.data();
  // Oddity: createTime and updateTime are only available from Firestore Admin
  // https://googleapis.dev/nodejs/firestore/latest/DocumentSnapshot.html
  // but not firebase.firestore web SDK
  // https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot
  return {
    ...defaultViewSelector(snap),
    businessPhone: data.businessPhone ? formatPhone(data.businessPhone) : data.businessPhone,
  };
};

export const selectApplicationViewAdapter = (snap: unknown) =>
  selectApplicationView(snap as UniversalSnapshot<Application>);

export type ApplicationTicketAttachment = {
  name: string;
  label: string;
  cloudPath: string;
  createdAt: UniversalTimestamp;
  uid: string;
  attachmentId: string;
  ticketId: string;
  ticketCommentId?: string;
  ticketCommentInternalId?: string;
};

// Application Dispute History:
export type ApplicationDisputeReason = {
  key: string;
  value: null | string | string[] | boolean;
};

export type ApplicationDisputeHistory = {
  disposition: Dispositions;
  reasons: ApplicationDisputeReason[];
  createdAt: UniversalTimestamp;
};

// NB! This union type depends on getApplicationDuplicates > fields
export type ApplicationDuplicateValueType = string | number | UniversalTimestamp | null | undefined;

// dateOfBirthAndSsn is a virtual property we are calculating dynamically
export type ApplicationDuplicateMatchingData = Partial<
  Record<keyof Application | 'dateOfBirthAndSsn', {comment: string; label: string; value: string}>
>;

export type ApplicationDuplicate = {
  distinguishableId: string;
  groupName: string;
  agentFullName: string;
  doingBusinessAs: string;
  legalBusinessName: string;
  signerFullName: string;
  matchingData: ApplicationDuplicateMatchingData;
};

export type ApplicationDuplicates = Record<string, ApplicationDuplicate>;
